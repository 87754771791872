import Centre from "./models/Centre";

export default function getCentersForEventGroup(eventGroupName: string): Centre[] {
    const loweredEventGroupName = eventGroupName?.toLocaleLowerCase();
    switch (loweredEventGroupName) {
        case "anz-kishor-kishori-shibir":
            return [
                Centre.Sydney,
                Centre.MelbourneMandir,
                Centre.MelbourneSouth,
                Centre.MelbourneWest,
                Centre.Brisbane,
                Centre.Canberra,
                Centre.Adelaide,
                Centre.Perth,
                Centre.PerthMandir,
                Centre.PerthSouth,
                Centre.Darwin
            ];
        case "bal-balika-exam-2024" :
            return  [
                Centre.Adelaide,
                Centre.Albury,
                Centre.Balwyn,
                Centre.Brisbane,
                Centre.Canberra,
                Centre.Cranbourne,
                Centre.Darwin,
                Centre.Geelong,
                Centre.GoldCoast,
                Centre.Hobart,
                Centre.MelbourneMandir,
                Centre.MelbourneSouth,
                Centre.MelbourneWest,
                Centre.Perth,
                Centre.Shepparton,
                Centre.Sydney,
            ];
        case "annakut-2024":
        case "annakut-2023" :
           return [
               Centre.Adelaide,
               Centre.Albury,
               Centre.Ballarat,
               Centre.Bendigo,
               Centre.Brisbane,
               Centre.BrisbaneNorth,
               Centre.Canberra,
               Centre.Darwin,
               Centre.Geelong,
               Centre.GoldCoast,
               Centre.Griffith,
               Centre.Hobart,
               Centre.MelbourneMandir,
               Centre.MelbourneSouth,
               Centre.MelbourneWest,
               Centre.MelbourneEast,
               Centre.Newcastle,
               Centre.PerthMandir,
               Centre.PerthSouth,
               Centre.Shepparton,
               Centre.Sydney,
               Centre.Toowoomba,
            ]
    }

    return [
        Centre.Adelaide,
        Centre.Albury,
        Centre.Brisbane,
        Centre.Balwyn,
        Centre.Canberra,
        Centre.Dubbo,
        Centre.Darwin,
        Centre.GoldCoast,
        Centre.MelbourneMandir,
        Centre.MelbourneWest,
        Centre.MelbourneSouth,
        Centre.Mittagong,
        Centre.Perth,
        Centre.Hobart,
        Centre.Sydney,
        Centre.Gatton,
        Centre.Griffith,
        Centre.Geelong,
        Centre.Gosford,
        Centre.Newcastle,
        Centre.Shepparton,
        Centre.Tamworth,
        Centre.Toowoomba,
        Centre.PerthMandir,
        Centre.PerthSouth,
        Centre.Auckland,
        Centre.Wellington,
        Centre.Christchurch
    ];
}
